import React from 'react'
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  Box
} from '@mui/material';
import { styled } from '@mui/system';
import useRole from '../../controllers/viewsControllers/role';
import useComponent from '../../components';
import { Edit, Delete, Save } from '@mui/icons-material';
import { CustomButton } from '../../components/CustomButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
  },
}))

const Roles = () => {
  const {
    positions,
    searchTerm,
    editingId,
    editingName,
    totalPages,
    handleSearchItem,
    handleEditSave,
    handleEditStart,
    setEditingName,
    handleGetRoles,
    handleKeyDown
  } = useRole();

  const { CustomPagination } = useComponent();

  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
        <Typography variant="h4" marginBottom={3} color="primary">
          Roles
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            value={searchTerm}
            onChange={handleSearchItem}
            placeholder="Buscar por rol"
            variant="outlined"
            size="small"
            fullWidth
          />
          <CustomButton
            text="Buscar"
            onClick={handleGetRoles}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyDown}
            variant="contained"
            color="primary"
            size="medium"
            disabled={searchTerm === ''}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                positions?.map((position) => (
                  <TableRow key={position.id}>
                    <TableCell>
                      {editingId === position.id ? (
                        <TextField
                          value={editingName}
                          onChange={(e) => setEditingName(e.target.value)}
                          variant="standard"
                          sx={{ width: '100%' }}
                        />
                      ) : (
                        position.name
                      )}
                    </TableCell>
                    <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                      {editingId === position.id ? (
                        <Tooltip title="Guardar">
                          <IconButton onClick={() => handleEditSave(position)} size="small">
                            <Save />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                          <Tooltip title="Editar">
                            <IconButton onClick={() => handleEditStart(position)} size="small">
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Eliminar">
                            <IconButton onClick={() => handleEditStart(position)} size="small">
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination totalItems={totalPages} onPageChange={handleGetRoles} />
      </Container>
    </Box >
  )
}

export default Roles