import axios from 'axios';
import useAuth from './auth';


const useUserService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, logout } = useAuth();
  const token = getToken();
  if (!token) logout();

  const getUsersBySupervisorId = async (supervisorId) => {
    try {
      const response = await axios.get(`${apiUrl}/users/supervised-users`, {
        params: { supervisorId },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the users:', error);
      return [];
    }
  };

  const getUserByRoles = async (roleIds) => {
    try {
      const queryString = roleIds.join(',');
      const response = await axios.get(`${apiUrl}/users/roles`, {
        params: { roleIds: queryString },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
    catch (error) {
      console.error('There was an error fetching the users:', error);
      return [];
    }
  };

  const getUserByRolesLocation = async (rolesId, workLocationsId) => {
    try {
      const roles = rolesId.join(',');
      const workLocations = workLocationsId.join(',');

      const response = await axios.get(`${apiUrl}/users/roles-locations`, {
        params: { roleIds: roles, workLocationIds: workLocations },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
    catch (error) {
      console.error('There was an error fetching the users:', error);
      return [];
    }
  };

  const getUsers = async ({ page = 1, searchTerm = '', positionsPerPage = 10}) => {
    try {
      const response = await axios.get(`${apiUrl}/users?page=${page}&searchTerm=${searchTerm}&limit=${positionsPerPage}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
    catch (error) {
      console.error('There was an error fetching the users:', error);
      return [];
    }
  }


  return {
    getUsersBySupervisorId,
    getUserByRolesLocation,
    getUserByRoles,
    getUsers,
  };
}

export default useUserService;