import React from 'react'
import {
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Tooltip,
  Box
} from '@mui/material';
import { styled } from '@mui/system';
import useUserHandlers from '../../controllers/viewsControllers/user';
import useComponent from '../../components';
import { Delete, Edit, Save } from '@mui/icons-material';
import EditUser from './EditUser';
import { CustomButton } from '../../components/CustomButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
  },
}))

const User = () => {
  const { useUser } = useUserHandlers();
  const {
    users,
    searchTerm,
    totalPages,
    handleGetUsers,
    handleKeyDown,
    editingId,
    editingName,
    setEditingName,
    handleEditSave,
    handleShowEdit,
    handleSearchItem,
    isEditDialogOpen,
    editingUser
  } = useUser();

  const { CustomPagination } = useComponent();

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: 'background.default',
        color: 'text.primary'
      }}>
        <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
          <Typography variant="h4" marginBottom={3} color="primary">
            Usuarios
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              value={searchTerm}
              onChange={handleSearchItem}
              placeholder="Buscar por nombres, apellidos, documento o correo"
              variant="outlined"
              size="small"
              fullWidth
            />
            <CustomButton
              text="Buscar"
              onClick={handleGetUsers}
              onKeyDown={handleKeyDown}
              onKeyPress={handleKeyDown}
              variant="contained"
              color="primary"
              size="medium"
              disabled={searchTerm === ''}
            />
            <CustomButton
              text="Agregar"
              onClick={() => handleShowEdit()}
              variant="contained"
              color="primary"
              size="medium"
            />
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Documento</StyledTableCell>
                  <StyledTableCell>Nombres</StyledTableCell>
                  <StyledTableCell>Apellidos</StyledTableCell>
                  <StyledTableCell>Correo</StyledTableCell>
                  <StyledTableCell>Acciones</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>
                        {editingId === user.id ? (
                          <TextField
                            value={editingName}
                            onChange={(e) => setEditingName(e.target.value)}
                            variant="standard"
                            sx={{ width: '100%' }}
                          />
                        ) : (
                          user.id
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === user.id ? (
                          <TextField
                            value={editingName}
                            onChange={(e) => setEditingName(e.target.value)}
                            variant="standard"
                            sx={{ width: '100%' }}
                          />
                        ) : (
                          user.first_name
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === user.id ? (
                          <TextField
                            value={editingName}
                            onChange={(e) => setEditingName(e.target.value)}
                            variant="standard"
                            sx={{ width: '100%' }}
                          />
                        ) : (
                          user.last_name
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === user.id ? (
                          <TextField
                            value={editingName}
                            onChange={(e) => setEditingName(e.target.value)}
                            variant="standard"
                            sx={{ width: '100%' }}
                          />
                        ) : (
                          user.email
                        )}
                      </TableCell>
                      <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                        {editingId === user.id ? (
                          <Tooltip title="Guardar">
                            <IconButton onClick={() => handleEditSave} size="small">
                              <Save />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                            <Tooltip title="Editar">
                              <IconButton onClick={() => handleShowEdit(user)} size="small">
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar">
                              <IconButton size="small">
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination totalItems={totalPages} onPageChange={handleGetUsers} />
        </Container>
      </Box>
      <EditUser isEditDialogOpen={isEditDialogOpen} handleShowEdit={handleShowEdit} user={editingUser} />
    </>
  );
};

export default User;