import { useState, useEffect } from 'react';
import useLocationService from '../../../services/location';
import useActivityService from '../../../services/activity';
import useAuth from '../../../services/auth';
import userService from '../../../services/user';
import useSchedulePerformanceService from '../../../services/schedulePerformance';
import useVehicleService from '../../../services/vehicle';
import { useSnackbar } from '../../../components/CustomSnackBar';
import { jwtDecode } from 'jwt-decode';

const usePublicLighting = () => {
  const [activities, setActivities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedActivityQuantity, setSelectedActivityQuantity] = useState('');
  const [selectedOT, setSelectedOT] = useState('');
  const [selectedCrew, setSelectedCrew] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [activityPairs, setActivityPairs] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [actualUserId, setActualUserId] = useState([]);
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedOtherVehicle, setSelectedOtherVehicle] = useState('');
  // services
  const { getLocations } = useLocationService();
  const { getActivities } = useActivityService();
  const { getToken } = useAuth();
  const { getUserByRolesLocation } = userService();
  const { getVehicles } = useVehicleService();
  const { createSchedulePerformance } = useSchedulePerformanceService();

  // consts
  const showSnackbar = useSnackbar();

  // functions
  const handleFieldChange = ({ value, arrayValue = null, setValue, localStorageKey }) => {
    let auxValue = [];

    if (!arrayValue) {
      setValue(value);
      localStorage.setItem(localStorageKey, value);

    } else {
      if (value.length > 0) {
        if (new Set(value.map((x) => x.id)).size !== value.length) {
          showSnackbar('Elemento duplicado', 5000, 'error');
          return;
        }
        value.map((x) => auxValue.push(x.id));
        setValue(auxValue);
      } else {
        auxValue = value
        setValue(auxValue);
      }

      localStorage.setItem(localStorageKey, JSON.stringify(auxValue));
    }
  }

  const handleSelectedUsers = () => {
    const usersAux = users.map((user) => {
      if (selectedUsers.includes(user.id)) {
        return { id: user.id, first_name: `${user.first_name}`, last_name: `${user.last_name}` };
      }
      return null;
    }).filter(user => user !== null);

    return usersAux;
  };

  const handleSelectedVehicles = () => {
    const vehicleAux = vehicles.map((vehicle) => {
      if (selectedVehicle.includes(vehicle.id)) return { ...vehicle };

      return null;
    }).filter(vehicle => vehicle !== null);

    return vehicleAux;
  };

  const handleSelectedActivity = (id) => {
    if (!id) return { id: '', name: '' };
    const activity = activities.find((x) => x.id === id);

    return { id, name: activity?.name };
  };


  const handleAddActivity = () => {
    if (selectedActivity && selectedActivityQuantity) {

      setActivityPairs([
        ...activityPairs,
        { selectedActivity, selectedActivityQuantity }
      ]);

      localStorage.setItem('selectedUsersPL', JSON.stringify(selectedUsers));
      localStorage.setItem('activityPairsPL', JSON.stringify(
        [...activityPairs, { selectedActivity, selectedActivityQuantity }])
      );
      setSelectedActivity('');
      setSelectedActivityQuantity('');
    }

    localStorage.removeItem('activityPL');
    localStorage.removeItem('activityQuantityPL');
  };

  const handleFinishDisabled = () => {
    const isAnyCarSelected = selectedVehicle.length > 0 || selectedOtherVehicle !== '';

    return selectedUsers.length === 0 ||
      activityPairs.length === 0 ||
      !selectedCrew ||
      !isAnyCarSelected
  };

  const handleRemovePair = (index) => {
    setActivityPairs(activityPairs.filter((_, i) => i !== index));
    localStorage.setItem('activityPairsPL', JSON.stringify(activityPairs.filter((_, i) => i !== index)));
  };

  const handleGetActivityName = (id) => {
    if (!id) return '';

    const activity = activities.find((x) => x.id === id);
    return activity?.name;
  };

  const handleGetVehicleName = (id) => {

    const vehicle = vehicles.find((x) => x.id === id);
    if (!vehicle) return null;

    return `${vehicle.license_plate} - ${vehicle.vehicle_type.name}`;
  }

  const handleGetUserName = (id) => {
    if (!id) return '';

    const user = users.find((x) => x.id === id);
    return `${user.first_name} ${user.last_name}`;
  };

  const handleRemoveUser = (index) => {
    setSelectedUsers(selectedUsers.filter((_, i) => i !== index));
    localStorage.setItem('selectedUsersPL', JSON.stringify(selectedUsers.filter((_, i) => i !== index)));
  };

  const handleRemoveVehicle = (vehicleId) => {
    const auxVehicle = selectedVehicle.filter((element, i) => element !== vehicleId);
    setSelectedVehicle(auxVehicle);
    localStorage.setItem('vehiclesPL', JSON.stringify(auxVehicle));
  };

  const handleRemoveOtherVehicle = () => {
    setSelectedOtherVehicle('');
    localStorage.removeItem('otherVehiclePL');
  };

  const handleSave = async () => {
    const activityPairs = JSON.parse(localStorage.getItem('activityPairsPL'));
    const selectedUsers = JSON.parse(localStorage.getItem('selectedUsersPL'));
    const selectedVehicles =  JSON.parse(localStorage.getItem('vehiclesPL'));
    const otherPlate = localStorage.getItem('otherVehiclePL');
    const activityPL = localStorage.getItem('activityPL');
    const crew = localStorage.getItem('crewPL');
    const ot = localStorage.getItem('otPL');
    const city = localStorage.getItem('cityPL');

    const response = await createSchedulePerformance({
      selectedLocation: 0,
      selectedUsers,
      activityPairs,
      crew,
      ot,
      selectedVehicles,
      otherPlate,
      city,
      activityPL,
    });

    if (response.ok) {
      showSnackbar(response.message, 5000, 'success');

      if (users.findIndex((x) => x.id === actualUserId))
        setSelectedUsers([actualUserId]);

      setActivityPairs([]);
      setSelectedCrew('');
      setSelectedCity('');
      setSelectedOT('');
      setSelectedCrew(null);
      setSelectedVehicle([]);
      setSelectedOtherVehicle('');

      localStorage.removeItem('activityPairsPL');
      localStorage.removeItem('selectedUsersPL');
      localStorage.removeItem('locationPL');
      localStorage.removeItem('vehiclesPL');
      localStorage.removeItem('otherVehiclePL');
      localStorage.removeItem('otPL');
      localStorage.removeItem('crewPL');
      localStorage.removeItem('cityPL');
      localStorage.removeItem('activityPL');
      localStorage.removeItem('activityQuantityPL');
    } else {
      showSnackbar(response.message, 5000, 'error');
    }
  };


  // effects
  useEffect(() => {
    if (activities?.length === 0 || users?.length === 0 || actualUserId?.length === 0 || locations?.length === 0) return;

    setActivityPairs(JSON.parse(localStorage.getItem('activityPairsPL')) || []);
    setSelectedUsers(JSON.parse(localStorage.getItem('selectedUsersPL')) || [actualUserId]);
    setSelectedVehicle(JSON.parse(localStorage.getItem('vehiclesPL')) || []);
    setSelectedOtherVehicle((localStorage.getItem('otherVehiclePL') || ''));
    setSelectedCrew(localStorage.getItem('crewPL') || '');
    setSelectedCity(localStorage.getItem('cityPL') || '');
    setSelectedOT(localStorage.getItem('otPL') || '');
    setSelectedActivity(JSON.parse(localStorage.getItem('activityPL')) || '');
    setSelectedActivityQuantity(localStorage.getItem('activityQuantityPL') || '');
  }, [users, activities, actualUserId, locations]);

  useEffect(() => {
    const fetchLocations = async () => {
      const locationsData = await getLocations();
      setLocations(locationsData);
    }

    const fetchActivities = async () => {
      const activitiesData = await getActivities({ description: 'AP' });
      setActivities(activitiesData);
    };

    const fetchUsers = async () => {
      const token = getToken();
      const decodedToken = jwtDecode(token);
      const users = await getUserByRolesLocation([11, 65, 54, 27, 26, 24, 25], [4, 3, 8]);

      const actualUser = users.find((x) => x.id === decodedToken.id);

      setActualUserId(actualUser?.id);
      setSelectedUsers([actualUser?.id]);

      if (localStorage.getItem('selectedUsersPL') === null) {
        localStorage.setItem('selectedUsersPL', JSON.stringify([actualUser?.id]));
      }

      setUsers(users);
    };

    const fetchVehicles = async () => {
      const vehiclesData = await getVehicles({ page: -1, positionsPerPage: -1 });
      setVehicles(vehiclesData.vehicles);
    };

    fetchActivities();
    fetchUsers();
    fetchLocations();
    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleFieldChange,
    handleSelectedUsers,
    handleSelectedVehicles,
    handleSelectedActivity,
    handleAddActivity,
    handleFinishDisabled,
    handleRemovePair,
    handleGetActivityName,
    handleGetVehicleName,
    handleGetUserName,
    handleRemoveUser,
    handleRemoveVehicle,
    handleRemoveOtherVehicle,
    handleSave,
    setSelectedCrew,
    setSelectedOT,
    setSelectedUsers,
    setActivityPairs,
    setSelectedActivity,
    setSelectedActivityQuantity,
    setSelectedVehicle,
    setSelectedOtherVehicle,
    setSelectedCity,
    selectedCity,
    selectedOtherVehicle,
    selectedUsers,
    selectedActivity,
    selectedActivityQuantity,
    selectedCrew,
    selectedOT,
    selectedVehicle,
    activities,
    users,
    activityPairs,
    actualUserId,
    vehicles,
  }
}

export default usePublicLighting;