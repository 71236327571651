import CustomPagination from './CustomPagination';
import CustomDatePicker from './CustomDatePicker';
import CustomRoleSelect from './CustomRoleSelect';
import CustomUserSelect from './CustomUserSelect';
import CustomInput from './CustomInput';

const useComponent = () => {
  return {
    CustomPagination,
    CustomDatePicker,
    CustomRoleSelect,
    CustomUserSelect,
    CustomInput
    };
}

export default useComponent;