import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useComponent from '../components';


const DynamicList = ({ title, color, items, onAddItem, onDeleteItem }) => {
  const [newItem, setNewItem] = useState('');

  const { CustomInput } = useComponent();

  const handleAddItem = () => {
    if (newItem.trim() !== '') {
      onAddItem(newItem.trim());
      setNewItem('');
    }
  };

  return (
    <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Box>
          <Box spacing={1} alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CustomInput
                label="Agregar recomendación"
                name="identifier"
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
                fullWidth
                margin="normal"
              />
              <IconButton onClick={handleAddItem} color="primary" aria-label="agregar elemento">
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
          <List>
            {items?.map((item, index) => (
              <ListItem key={index} sx={{ borderTop: "1px solid", borderColor: 'neutral.dark' }}>
                <ListItemText primary={item} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="eliminar" onClick={() => onDeleteItem(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Paper>
  );
};

export default DynamicList;