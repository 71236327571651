export const EvaluationTypes = {
  ANNUAL: 'annual',
  PERIOD_OF_WORK: 'period-of-work',
  FOLLOW_UP: 'follow-up',
};

export const evaluationTypeNames = {
  [EvaluationTypes.ANNUAL]: 'Anual',
  [EvaluationTypes.PERIOD_OF_WORK]: 'Por periodo de prueba',
  [EvaluationTypes.FOLLOW_UP]: 'Seguimiento',
};

export const getEvaluationTypeName = (id) => {
  return evaluationTypeNames[id] || '';
};

export const ratingScaleLikart = [5, 4, 3, 2, 1];