import {
  Box,
  Pagination,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from '@mui/material';
import usePagination from '../../controllers/componentsControllers/Pagination';

const CustomPagination = ({ totalItems,  onPageChange }) => {

  const {
    positionsPerPage,
    currentPage,
    handlePositionsPerPageChange,
    handlePageChange
  } = usePagination({ totalItems, onPageChange });

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      <FormControl variant="outlined" size="small" sx={{ width: "85px" }}>
        <InputLabel id="positions-per-page-label">Por página</InputLabel>
        <Select
          labelId="positions-per-page-label"
          value={positionsPerPage}
          onChange={handlePositionsPerPageChange}
          label="Por página"
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        count={totalItems}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
      />
    </Box>
  )
}

export default CustomPagination;