import axios from 'axios';
import useAuth from './auth';

const useSchedulePerformanceService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, logout } = useAuth();
  const token = getToken();

  if (!token) logout();

  const createSchedulePerformance = async (schedulePerformance) => {
    try {
      const response = await axios.post(`${apiUrl}/schedule-performance/create`, schedulePerformance, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error creating the evaluation:', error.response.data);
      return error.response.data;
    }
  }

  const getSchedulePerformance = async () => {
    try {
      const response = await axios.get(`${apiUrl}/schedule-performance`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
    catch (error) {
      console.error('There was an error getting the schedule performance:', error);
      return [];
    }
  }

  const getPublicLightReport = async () => {
    try {
      const response = await axios.get(`${apiUrl}/schedule-performance/public-light`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
    catch (error) {
      console.error('There was an error getting the schedule performance:', error);
      return [];
    }
  }

  return {
    createSchedulePerformance,
    getSchedulePerformance,
    getPublicLightReport
  };
}

export default useSchedulePerformanceService;