import React from 'react';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

export const CustomLink = React.forwardRef(({ to, children, sx, isActive = true, textDecoration = 'none' }, ref) => {
  const theme = useTheme();
  const { palette } = theme;

  return (
    <Box
      component={Link}
      to={to}
      ref={ref}
      sx={{
        color: isActive ? palette.primary.main : 'white',
        textDecoration: textDecoration,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 0.5,
        display: 'flex',
        '&:hover': {
          color: !isActive ? palette.error.main : palette.primary.main,
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
});