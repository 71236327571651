import axios from 'axios';
import useAuth from './auth';

const useActivityService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, logout } = useAuth();

  const token = getToken();
  if (!token) logout();

  const getActivities = async ({ description = '' }) => {
    try {
      const response = await axios.get(`${apiUrl}/activities?description=${description}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the activities:', error);
      return [];
    }
  };

  const getActivityById = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/activities/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
    } catch (error) {
      console.error('There was an error fetching the activities:', error);
      return [];
    }
  };

  return {
    getActivities,
    getActivityById
  };
}

export default useActivityService;