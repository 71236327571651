import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import useComponentsControllers from '../../controllers/componentsControllers';

const CustomRoleSelect = ({ value, onChange }) => {
  const { useRoleSelect } = useComponentsControllers();
  const { roles, selectedRole, handleSelectedRole } = useRoleSelect();

  return (
    <Autocomplete
      options={roles}
      getOptionKey={(role) => role.id}
      value={selectedRole}
      getOptionLabel={(option) => option.name}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label="Roles" />}
      onChange={handleSelectedRole}
    />
  );
};
export default CustomRoleSelect;
