import useMap from './Map';
import useRoleSelect from './RoleSelect';
import useUserSelect from './UserSelect';

const useComponentsControllers = () => {
  return {
    useMap,
    useRoleSelect,
    useUserSelect
  }
}
export default useComponentsControllers;