/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useUserService from '../../../services/user';

const useUserSelect = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const { getUsers } = useUserService();

  const handleSelectedRole = (event, value) => {
    setSelectedUser(value)
  }

  useEffect(() => {
    const hadleGetUsers = async () => {
      const response = await getUsers({page: -1, positionsPerPage: -1});
      setUsers(response.users)
    }

    hadleGetUsers()
  }, [])

  return {
    users,
    selectedUser,
    handleSelectedRole
  }
}

export default useUserSelect;