import React, { useEffect, useState } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Paper
} from '@mui/material';
import EvaluationTable from './EvaluationTable';
import { CustomButton } from './CustomButton';
import useEvaluationService from '../services/evaluation';
import ImprovementPlan from './ImprovementPlan';
import { useSnackbar } from './CustomSnackBar';
import EvaluationColumns from './Calification';
import { evaluationTypeNames } from '../services/constants';
import Swal from 'sweetalert2'

const Evaluation = ({
  evaluationType,
  setEvaluationType,
  factors,
  isSuperVisor,
  evaluatorId,
  selectedEmployee,
  onEmployeeRatingsChange,
  setManagerRatings,
  setEmployeeRatings,
  onManagerRatingsChange,
  employeeRatings,
  managerRatings
}) => {
  const [isActiveEvaluation, setIsActiveEvaluation] = useState(false);
  const [evaluation, setEvaluation] = useState([]);
  const [evaluationStatus, setEvaluationStatus] = useState('');
  const [evaluatorActions, setEvaluatorActions] = useState([]);
  const [workerCommitments, setWorkerCommitments] = useState([]);

  const showSnackbar = useSnackbar();

  // services
  const { createEvaluation, updateEvaluation, getEvaluationByUserId } = useEvaluationService();

  // functions
  const getNextStatus = (status, isSuperVisor) => {

    const isEvaluatioComplete = isEvaluationComplete(factors, managerRatings, employeeRatings, status);

    const statusMap = {
      'started': isSuperVisor ? 'pending_for_employee' : 'pending_for_manager',
      'pending_for_employee': isEvaluatioComplete ? 'completed' : 'pending_for_manager',
      'pending_for_manager': isEvaluatioComplete ? 'completed' : 'pending_for_employee',
      'completed': isSuperVisor ? 'rated' : 'completed'
    };

    return statusMap[status] || 'started';
  };

  const isEvaluationComplete = (factors, managerRatings = [], employeeRatings = [], status) => {
    //TOOD revisar esto
    if (status === 'rated') return status;
    return factors?.length === Object.keys(employeeRatings)?.length && factors?.length === Object.keys(managerRatings).length;
  };

  const handleStatus = () => {
    const status = evaluation[0]?.status || 'started';

    return getNextStatus(status, isSuperVisor);
  };

  const handleGetEvaluationByUserId = async () => {
    if (!selectedEmployee) return;

    const response = await getEvaluationByUserId(selectedEmployee);

    if (response[0]?.status !== 'rated') {
      const isComplete = isEvaluationComplete(factors, response[0]?.manager_ratings, response[0]?.employee_ratings, response[0]?.status)
      if (isComplete) setEvaluationStatus('completed');
      else setEvaluationStatus(response[0]?.status);
    } else {
      setEvaluationStatus('rated');
    }

    setEvaluatorActions(response[0]?.evaluator_actions);
    setWorkerCommitments(response[0]?.worker_commitments);
    setEmployeeRatings(response[0]?.employee_ratings);
    setManagerRatings(response[0]?.manager_ratings);
    setEvaluationType(response[0]?.type);
    setEvaluation(response.length === 0 ? [] : [response[0]]);

  };

  const handleEvaluationType = (event) => setEvaluationType(event.target.value);

  const handleRateEvaluation = async () => {
    Swal.fire({
      title: '¡Atención!',
      text: "¿Está seguro que desea calificar la evaluación? Una vez calificada no podrá modificarla.",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#6dad48',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#575756',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { status: 'rated' };

        const response = await updateEvaluation({ id: evaluation[0].id, data });

        if (response.ok) {
          showSnackbar(response.message, 5000, 'success');
          handleGetEvaluationByUserId();
        } else {
          showSnackbar(response.message, 5000, 'error');
        }
      }
    });

  }


  const handleSave = async () => {
    const status = handleStatus();

    const data = {
      ...(isSuperVisor ? { manager_ratings: managerRatings } : { employee_ratings: employeeRatings }),
      ...(status !== 'rated' && { status }),
      evaluator_actions: evaluatorActions,
      worker_commitments: workerCommitments
    };

    const response = await updateEvaluation({ id: evaluation[0].id, data });
    if (response.ok) {
      showSnackbar(response.message, 5000, 'success');
      handleGetEvaluationByUserId();
    } else {
      showSnackbar(response.message, 5000, 'error');
    }
  };

  const handleCreateEvaluation = async (evaluationType) => {
    const data = {
      employee_id: selectedEmployee,
      evaluator_id: evaluatorId,
      type: evaluationType
    };
    const response = await createEvaluation(data);

    if (response?.id) handleGetEvaluationByUserId();

  };

  useEffect(() => {
    setEvaluationType('');
    setIsActiveEvaluation(false);

    handleGetEvaluationByUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee]);

  if (evaluationStatus === '') return;


  return (
    <>
      {isSuperVisor && (evaluation?.length === 0 || evaluationStatus === 'rated') && !isActiveEvaluation &&
        <FormControl
          sx={{ mb: 4, display: 'flex', flexDirection: 'row', gap: '20px', width: '100%', justifyContent: 'space-between' }}
        >
          <InputLabel>Seleccione un tipo de evaluación</InputLabel>
          <Select
            id="evaluation-type-select"
            value={evaluationType}
            label="Seleccione un tipo de evaluación"
            onChange={handleEvaluationType}
            sx={{ width: '80%' }}
          >
            <MenuItem key={''} value={''} selected>
              {'Seleccione un tipo de evaluación'}
            </MenuItem>
            {
              Object.entries(evaluationTypeNames).map((type) => (
                <MenuItem key={type[0]} value={type[0]}>
                  {type[1]}
                </MenuItem>
              ))
            }
          </Select>

          <CustomButton
            type="button"
            disabled={!evaluationType}
            sx={{ width: "30%" }}
            onClick={() => handleCreateEvaluation(evaluationType)}
            text='Crear'
          />
        </FormControl >
      }

      {evaluation?.length === 0 && !isActiveEvaluation &&
        <Box sx={{ textAlign: 'left' }} >
          <Typography variant="h6" gutterBottom color="primary">
            {'No tiene evaluación activa'}
          </Typography>
        </Box>
      }

      {(isActiveEvaluation || evaluation?.length > 0) && evaluationStatus !== 'rated' &&
        <>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: '#4a4a4a',
              color: 'white'
            }}
          >
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
              <Box component="span" sx={{ fontSize: '1.2rem', borderRight: '1px solid white', lineHeight: 1, paddingRight: '10px' }}>
                <Typography component="span" sx={{ color: '#6dad48', fontSize: '1.5rem', marginRight: '5px' }}>5</Typography>
                Valor más <strong>alto</strong> de desempeño
              </Box>
              <Box component="span" sx={{ fontSize: '1.2rem', lineHeight: 1 }}>
                <Typography component="span" sx={{ color: '#FF6B6B', fontSize: '1.5rem', marginRight: '5px' }}>1</Typography>
                Valor más <strong>bajo</strong> de desempeño
              </Box>
            </Typography>
          </Paper>

          <EvaluationTable isSuperVisor={isSuperVisor} managerRatings={managerRatings} employeeRatings={employeeRatings} rater={'employee'} onChange={onEmployeeRatingsChange} factors={factors} />

          {
            isSuperVisor && (
              <>
                <EvaluationTable isSuperVisor={isSuperVisor} managerRatings={managerRatings} employeeRatings={employeeRatings} rater={'manager'} onChange={onManagerRatingsChange} factors={factors} />

                <ImprovementPlan
                  evaluatorActions={evaluatorActions}
                  workerCommitments={workerCommitments}
                  setEvaluatorActions={setEvaluatorActions}
                  setWorkerCommitments={setWorkerCommitments}
                />
              </>
            )
          }

          {
            evaluationStatus !== 'rated' &&
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: '2rem' }}>
              {evaluationStatus !== 'rated' &&
                <CustomButton
                  type="button"
                  isLoading={false}
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => handleSave()}
                  text='Guardar'
                />
              }

              {(evaluationStatus === 'completed' && isSuperVisor) &&
                <CustomButton
                  type="button"
                  isLoading={false}
                  color="error"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => handleRateEvaluation()}
                  text='Calificar'
                />
              }
            </Box>
          }
        </>
      }

      {evaluationStatus === 'rated' &&
        <EvaluationColumns factors={factors} evaluation={evaluation} isSuperVisor={isSuperVisor} />
      }
    </>
  );
}

export default Evaluation;