import React from 'react';
import { Avatar, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import { CustomButton } from '../../components/CustomButton';
import useComponent from '../../components';
import useUserEdit from '../../controllers/viewsControllers/user/userEdit';

const EditUser = ({ isEditDialogOpen, handleShowEdit, user }) => {
  const {
    positions,
    isEditMode,
    handleDialogClose,
    handleDateChange,
    handlePositionChange,
    userEdit
  } = useUserEdit({ handleShowEdit, user });
  const { CustomDatePicker, CustomUserSelect, CustomRoleSelect, CustomInput } = useComponent();

  return (
    <Dialog
      open={isEditDialogOpen}
      onClose={handleDialogClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        '& .MuiDialog-container': {
          width: '100%',
        },
      }}
      PaperProps={{
        sx: {
          width: '100%',
        }
      }}
    >
      <DialogTitle>
        {isEditMode ? `Editar Usuario: ${user?.first_name}` : `Crear usuario`}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Avatar
              src={user?.picture_profile}
              alt={`${user?.first_name} ${user?.last_name}`}
              sx={{ width: 100, height: 100 }}
            />
            <Button variant="contained" component="label">
              {isEditMode ? 'Cambiar foto' : 'Subir foto'}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => {
                  console.log(e.target.files);
                  const file = e.target.files?.[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                      if (event.target?.result) {
                        console.log(event.target.result);
                      }
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <CustomDatePicker
            label="Fecha de contratación"
            value={userEdit?.hire_date}
            onChange={(date) => handleDateChange(date)}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <CustomRoleSelect onChange={(e) => console.log(e.target.value)} />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Seleccione una posición</InputLabel>
            <Select
              label="Seleccione una posición"
              value={user?.position}
              onChange={handlePositionChange}
              sx={{ borderRadius: '8px' }}
            >
              {positions.map((position) => (
                <MenuItem key={position} value={position}>
                  {position}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>


        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <CustomInput
            label="Número de documento"
            type="text"
            value={user?.email}
            onChange={(e) => console.log(e.target.value)}
            fullWidth
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <CustomInput
            label="Nombres"
            type="text"
            value={user?.email}
            onChange={(e) => console.log(e.target.value)}
            fullWidth
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <CustomInput
            label="Apellidos"
            type="text"
            value={user?.email}
            onChange={(e) => console.log(e.target.value)}
            fullWidth
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <CustomInput
            label="Correo electrónico"
            type="email"
            value={user?.email}
            onChange={(e) => console.log(e.target.value)}
            fullWidth
          />
        </Box>


        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <CustomUserSelect label="Supervisor" />
        </Box>


      </DialogContent>

      <DialogActions>
        <Button onClick={handleDialogClose}>Cancelar</Button>
        <CustomButton
          type="button"
          isLoading={false}
          onClick={handleShowEdit}
          text='Guardar'
        />

      </DialogActions>

    </Dialog>
  );
}

export default EditUser;