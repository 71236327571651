/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useRoleService from '../../../services/role';

const useRoleSelect = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  const { getRoles } = useRoleService();

  const handleSelectedRole = (event, value) => {
    setSelectedRole(value)
  }

  useEffect(() => {
    const hadleGetRoles = async () => {
      const response = await getRoles({page: -1});
      setRoles(response.roles)
    }

    hadleGetRoles()
  }, [])

  return {
    roles,
    selectedRole,
    handleSelectedRole
  }
}

export default useRoleSelect;