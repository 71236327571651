import { useState, useEffect } from "react";

const useUserEdit = ({ handleShowEdit, user }) => {
  const [isEditMode, setIsEditMode] = useState(!!user);
  const [userEdit, setUserEdit] = useState(null);

  const positions = [
    "ADMINISTRATIVO-PROYECTOS",
    "OPERATIVO",
    "ADMINISTRATIVO",
  ]

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleShowEdit();
  };

  const handleDateChange = (date) => {
    const dateString = date ? date.format("YYYY-MM-DD") : "";
    console.log(dateString);
  };

  const handlePositionChange = (event) => {
    setUserEdit((prev) => ({ ...prev, position: event.target.value }));
  }


  useEffect(() => {
    if (user) {
      setUserEdit(user);
    }else{
      setIsEditMode(false);
    }
  }, [user])

  return {
    positions,
    isEditMode,
    userEdit,
    handleDialogClose,
    handleDateChange,
    handlePositionChange,
  }
}

export default useUserEdit
