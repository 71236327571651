import axios from 'axios';
import useAuth from './auth';

const useRoleService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, logout } = useAuth();

  const token = getToken();
  if (!token) logout();

  const getRoles = async ({ page = 1, searchTerm = '', positionsPerPage = 10 }) => {
    try {
      const response = await axios.get(`${apiUrl}/roles?page=${page}&searchTerm=${searchTerm}&limit=${positionsPerPage}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the roles:', error);
      return [];
    }
  };

  const getRoleById = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/roles/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
    } catch (error) {
      console.error('There was an error fetching the role:', error);
      return [];
    }
  };

  return {
    getRoles,
    getRoleById
  };
}

export default useRoleService;