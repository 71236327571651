/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

const usePagination = ({ totalItems, onPageChange }) => {
  const [positionsPerPage, setPositionsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  const handlePositionsPerPageChange = (event) => {
    setCurrentPage(1)
    setPositionsPerPage(event.target.value)
  }

  const handlePageChange = async (event, value) => {
    setCurrentPage(value);
    onPageChange(value, positionsPerPage)
  }

  useEffect(() => {
    onPageChange(currentPage, positionsPerPage)
  }, [totalItems, positionsPerPage])

  return {
    positionsPerPage,
    currentPage,
    handlePageChange,
    handlePositionsPerPageChange,
    setPositionsPerPage,
    setCurrentPage
  }
}

export default usePagination;