import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import useComponentsControllers from '../../controllers/componentsControllers';

const CustomUserSelect = ({ value, onChange, label = 'Usuarios' }) => {
  const { useUserSelect } = useComponentsControllers();
  const { users, selectedUser, handleSelectedUser } = useUserSelect();

  return (
    <Autocomplete
      options={users}
      getOptionKey={(user) => user.id}
      value={selectedUser}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={handleSelectedUser}
    />
  );
};
export default CustomUserSelect;
