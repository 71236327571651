/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import useRoleService from '../../../services/role'
import {
  useTheme,
} from '@mui/material';

const useRole = () => {
  const [positions, setPositions] = useState([])
  const [editingId, setEditingId] = useState(null)
  const [editingName, setEditingName] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [totalPages, setTotalPages] = useState(0)

  const { getRoles } = useRoleService();
  const theme = useTheme();

  const handleEditStart = (position) => {
    setEditingId(position.id)
    setEditingName(position.name)
  }

  const handleEditSave = () => {
    setPositions(positions.map(p =>
      p.id === editingId ? { ...p, name: editingName } : p
    ))
    setEditingId(null)
  }

  const handleSearchItem = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleGetRoles();
  };

  const handleGetRoles = async (currentPage, positionsPerPage) => {
    const response = await getRoles({ page: currentPage, searchTerm, positionsPerPage });

    setPositions(response.roles);
    setTotalPages(response.totalPages);
  }

  return {
    positions,
    theme,
    searchTerm,
    editingId,
    editingName,
    totalPages,
    handleSearchItem,
    handleEditSave,
    handleEditStart,
    setEditingName,
    handleGetRoles,
    handleKeyDown
  };

}

export default useRole;