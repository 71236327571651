import { Box, Paper, ListItemText, TextField, List, ListItem, IconButton, Tooltip } from '@mui/material';
import { Delete, Save, PolylineOutlined, Edit } from '@mui/icons-material';
import { CustomButton } from './CustomButton';
import useComponentsControllers from '../controllers/componentsControllers';

const MapComponent = () => {
  const { useMap } = useComponentsControllers();
  const { geofences,
    mapContainer,
    selectedId,
    editIndex,
    setEditIndex,
    isMobile,
    handleDelete,
    handleDrawPolygon,
    flyToGeofence,
    handleSaveGeofences,
    updateGeofenceName,
    isSelectedGeofenceNameEmpty
  } = useMap();

  return (
    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', height: '75vh', bgcolor: 'background.default' }}>
      <Paper
        elevation={3}
        sx={{
          width: isMobile ? '100%' : 300,
          height: isMobile ? 'auto' : '100%',
          p: 2,
          overflowY: 'auto',
          bgcolor: '#2a2a2a',
          color: 'white',
          flexShrink: 0,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
          <List>
            {geofences?.map((feature, index) => (
              <ListItem key={feature.id} disablePadding sx={{ mb: 1 }}>
                {editIndex === index ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <TextField
                      value={feature?.name}
                      onChange={(e) => updateGeofenceName(index, e.target.value)}
                      onClick={() => flyToGeofence(feature)}
                      autoFocus
                      onFocus={() => flyToGeofence(feature)}
                      variant="outlined"
                    />
                    <Tooltip title="Eliminar geocerca">
                      <IconButton onClick={() => handleDelete(feature.id)} size="small">
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  <>
                    <ListItemText
                      sx={{ display: 'flex', mr: 1, cursor: 'pointer', color: 'white', padding: '8px', borderBottom: '1px solid #e0e0e0' }}
                    >
                      {feature?.name}
                    </ListItemText>
                    <Tooltip title="Editar geocerca">
                      <IconButton onClick={() => setEditIndex(index)} size="small">
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

              </ListItem>
            ))}

          </List>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: 2,
          }}>
            <CustomButton
              onClick={handleDrawPolygon}
              text='Dibujar'
              startIcon={<PolylineOutlined />}
              fullWidth={isMobile}
            />
            {
              selectedId &&
              <CustomButton
                onClick={handleSaveGeofences}
                text='Guardar'
                color="primary"
                disabled={isSelectedGeofenceNameEmpty()}
                startIcon={<Save />}
                fullWidth={isMobile}
              />
            }
          </Box>
        </Box>

      </Paper>
      <Box sx={{ flexGrow: 1, position: 'relative', height: isMobile ? 'calc(100vh - 300px)' : '100%' }}>
        <div ref={mapContainer} style={{ height: "100%" }} />
      </Box>
    </Box>
  );
};

export default MapComponent;