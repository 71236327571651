import MapContainer from '../components/MapContainer';
import {
  Box,
  Typography,
  Container,
} from '@mui/material';

const GeoFence = () => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
        <Typography variant="h4" marginBottom={3} color="primary">
          Geocercas
        </Typography>
        <MapContainer />
      </Container >
    </Box >
  );
}

export default GeoFence;