import axios from 'axios';
import useAuth from './auth';

const useGeofenceService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, logout } = useAuth();

  const token = getToken();
  if (!token) logout();

  const getGeofences = async () => {
    try {
      const response = await axios.get(`${apiUrl}/geofences`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the geofences:', error);
      return [];
    }
  };

  const saveGeofence = async (data) => {
    try {
      const response = await axios.post(`${apiUrl}/geofences/save`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response;
    } catch (error) {
      console.error('There was an error creating the geofence:', error);
      return [];
    }
  }

  const deleteGeoFence = async (id) => {
    try {
      const response = await axios.delete(`${apiUrl}/geofences/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response;
    }
    catch (error) {
      console.error('There was an error deleting the geofence:', error);
      return [];
    }
  }

  return {
    getGeofences,
    saveGeofence,
    deleteGeoFence
  };
}

export default useGeofenceService;