import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CustomDatePicker = ({ value, onChange, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        defaultValue={''}
        value={dayjs(value)}
        onChange={onChange}
        renderInput={(params) => <input {...params.inputProps} />}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;