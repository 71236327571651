import useUser from './user';
import useUserEdit from './userEdit';

const useUserHandlers = () => {
  return {
    useUser,
    useUserEdit
  };
};

export default useUserHandlers;