/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import useUserService from '../../../services/user';

const useUser = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [users, setUsers] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [editingUser, setEditingUser] = useState(null)
  const [editingId, setEditingId] = useState(null)
  const [editingName, setEditingName] = useState('')
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

  const { getUsers } = useUserService();

  const handleSearchItem = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleEditStart = (position) => {
    setEditingId(position.id)
    setEditingName(position.first_name)
  }

  const handleEditSave = () => {
    setUsers(users.map(p =>
      p.id === editingId ? { ...p, name: editingName } : p
    ))
    setEditingId(null)
  }

  const handleShowEdit = (user) => {
    setEditingUser(user)
    setIsEditDialogOpen(!isEditDialogOpen)
  }

  const handleGetUsers = async (currentPage, positionsPerPage) => {
    const response = await getUsers({ page: currentPage, searchTerm, positionsPerPage });

    setUsers(response.users);
    setTotalPages(response.totalPages);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleGetUsers();
  };

  return {
    users,
    searchTerm,
    editingId,
    editingName,
    totalPages,
    editingUser,
    isEditDialogOpen,
    setEditingName,
    handleEditSave,
    handleEditStart,
    handleSearchItem,
    handleGetUsers,
    handleKeyDown,
    handleShowEdit,
  }
}

export default useUser