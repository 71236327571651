/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import useGeofenceService from '../../../services/geofence';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from '../../../components/CustomSnackBar';
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

const useMap = () => {
  // states
  const [geofences, setGeofences] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [editIndex, setEditIndex] = useState(null);

  // services
  const { getGeofences, saveGeofence, deleteGeoFence } = useGeofenceService();

  // refs
  const draw = useRef(null);
  const mapContainer = useRef(null);
  const map = useRef(null);


  // constants
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const showSnackbar = useSnackbar();
  mapboxgl.accessToken = 'pk.eyJ1IjoiZWxpdGVpbmdlbmllcm9zIiwiYSI6ImNtNTE4eTl2NjFvNHQya3E3ZXpvdXJjcjkifQ.g-p9jRlRzT0RwrTRXvKlkA';

  // functions
  const handleGetGeoFences = async () => {
    const response = await getGeofences();
    setGeofences(response);

    draw.current.deleteAll();

    response.forEach((feature) => {
      const featureWithId = {
        type: 'Feature',
        geometry: feature.geometry,
        id: feature.id,
      };

      try {
        draw.current.add(featureWithId);
      } catch (error) {
        console.error("Error adding feature:", error);
      }
    });

  };

  const initializeMap = () => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [-75.62608162551214, 6.147734380227902],
      zoom: 18,
      attributionControl: false,
      cooperativeGestures: true,
    });

    draw.current = new MapboxDraw({ displayControlsDefault: false });
    map.current.addControl(draw.current);
    map.current.addControl(new mapboxgl.FullscreenControl());

    map.current.on("load", () => {
      map.current.on("draw.create", handleGeofenceCreate);
      map.current.on("draw.update", handleGeofenceUpdate);
      map.current.on("draw.delete", handleGeofenceDelete);
      map.current.on("draw.selectionchange", handleSelectionChange);
    });
  };


  const handleGeofenceCreate = (e) => {
    const name = prompt("Ingrese un nombre para la geocerca:");
    if (name) {
      const newFeature = { ...e.features[0], properties: { ...e.features[0].properties, name }, name };
      setGeofences(prev => [...prev, newFeature]);
    } else {
      draw.current.delete(e.features[0].id);
    }
  };

  const handleGeofenceUpdate = () => {
    setGeofences(prev => updateGeofences(prev, draw.current.getAll()));
  };

  const updateGeofences = (prevGeofences, currentGeoFences) => {
    return prevGeofences.map((feature, index) => {
      const updatedGeometry = currentGeoFences.features[index].geometry
      feature.geometry = updatedGeometry;
      return feature;
    });
  };

  const handleGeofenceDelete = () => {
    setGeofences(draw.current.getAll().features);
    setSelectedId(null);
  };

  const handleSelectionChange = (e) => {
    const fences = draw.current.getAll();
    const actualId = e?.features[0]?.id;

    setEditIndex(fences.features.findIndex(x => x.id === actualId));
    setSelectedId(e.features.length > 0 ? e.features[0].id : null);
  };

  const handleDelete = async (id) => {
    if (id) {
      const response = await deleteGeoFence(id);

      if (response.status === 200) {
        draw.current.delete(id);
        setSelectedId(null);
        setGeofences(geofences.filter(feature => feature.id !== id));
        showSnackbar(response.data.message, 5000, 'success');
        setSelectedId(null);
        setEditIndex(null);
        handleGetGeoFences();
      }
    }
  };

  const handleDrawPolygon = () => draw.current.changeMode("draw_polygon");

  const flyToGeofence = (feature) => {
    if (!feature) return;

    const bbox = calculateBounds(feature.geometry.coordinates[0]);
    map.current.fitBounds(bbox, { padding: 50, duration: 1000 });
    setSelectedId(feature.id);
    draw.current.changeMode('simple_select', { featureIds: [feature.id] });
  };

  const calculateBounds = (coordinates) => {
    if (!coordinates || coordinates.length === 0) {
      return [[map.current.getCenter().lng, map.current.getCenter().lat], [map.current.getCenter().lng, map.current.getCenter().lat]];
    }
    return coordinates.reduce(
      (bbox, coord) => [
        [Math.min(bbox[0][0], coord[0]), Math.min(bbox[0][1], coord[1])],
        [Math.max(bbox[1][0], coord[0]), Math.max(bbox[1][1], coord[1])],
      ],
      [[Infinity, Infinity], [-Infinity, -Infinity]]
    );
  };

  const handleSaveGeofences = async () => {
    const auxGeoFence = geofences.find(x => x.id === selectedId);

    const response = await saveGeofence({
      id: selectedId,
      name: auxGeoFence.type ? auxGeoFence.properties.name : auxGeoFence.name,
      geometry: auxGeoFence.geometry
    });

    if (response.status === 200) {
      showSnackbar(response.data.message, 5000, 'success');
      setEditIndex(null);
      setSelectedId(null);
      handleGetGeoFences();
    }
  };

  const updateGeofenceName = (index, newName) => {
    const updatedGeofences = [...geofences];
    updatedGeofences[index].name = newName;
    setGeofences(updatedGeofences);
  };


  const isSelectedGeofenceNameEmpty = () => {

    const selectedGeofence = geofences?.find(geofence => {
      return geofence.id === selectedId
    }
    );

    return !selectedGeofence || !selectedGeofence.name;
  };

  // useEffects

  useEffect(() => {
    initializeMap();
    handleGetGeoFences();
  }, []);

  return {
    geofences,
    setGeofences,
    handleGetGeoFences,
    draw,
    map,
    mapContainer,
    selectedId,
    setSelectedId,
    editIndex,
    setEditIndex,
    isMobile,
    saveGeofence,
    deleteGeoFence,
    showSnackbar,
    initializeMap,
    handleDelete,
    handleDrawPolygon,
    flyToGeofence,
    handleSaveGeofences,
    updateGeofenceName,
    isSelectedGeofenceNameEmpty
  }


}

export default useMap;